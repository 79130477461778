<template>
  <div class="container">
    <h1 class="is-size-3">BuPlan Intel Agents</h1>
    <div class="about">
      <div class="column-head">AOR</div>
      <div class="column-head">Intel Agent</div>
      <div class="column-head">Contact</div>

      <div class="aor">1st Fleet</div>
      <div class="member">CAPTJG Wes Rist (First Fleet Ops Officer)</div>
      <div class="contact">
        <a :href="email('dwrist', 'gmail.com')">{{
          email("dwrist", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">2nd Fleet</div>
      <div class="member">LCDR Patrick Giese</div>
      <div class="contact">
        <a :href="email('masternav', 'icloud.com')">{{
          email("masternav", "icloud.com").substring(7)
        }}</a>
      </div>

      <div class="aor">3rd Fleet</div>
      <div class="member">CPO Brendan Lonehawk</div>
      <div class="contact">
        <a :href="email('brenhawk', 'gmail.com')">{{
          email("brenhawk", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">4th Fleet</div>
      <div class="member">VACANT*</div>
      <div class="contact">&nbsp;</div>

      <div class="aor">6th Fleet</div>
      <div class="member">CDR Ed Cook</div>
      <div class="contact">
        <a :href="email('edward.e.cook.jr', 'gmail.com')">{{
          email("edward.e.cook.jr", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">7th Fleet</div>
      <div class="member">VACANT*</div>
      <div class="contact">&nbsp;</div>

      <div class="aor">8th Fleet</div>
      <div class="member">VACANT*</div>
      <div class="contact">&nbsp;</div>

      <div class="aor">10th Fleet</div>
      <div class="member">CPO David Langmack</div>
      <div class="contact">
        <a :href="email('dmlangmack', 'gmail.com')">{{
          email("dmlangmack", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">13th Fleet</div>
      <div class="member">MCPO Dominika Janečková</div>
      <div class="contact">
        <a :href="email('pilot17', 'gmail.com')">{{
          email("pilot17", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">RHN</div>
      <div class="member">CPT Ian Brannan</div>
      <div class="contact">
        <a :href="email('ianbrannan', 'hotmail.com')">{{
          email("ianbrannan", "hotmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">RMMM</div>
      <div class="member">LTSG John Fairbairn</div>
      <div class="contact">
        <a :href="email('johnlfairbairn', 'gmail.com')">{{
          email("johnlfairbairn", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">RMA</div>
      <div class="member">BGEN Aaron Clarke</div>
      <div class="contact">
        <a :href="email('aeclarktrmn', 'gmail.com')">{{
          email("aeclarktrmn", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">Civilians</div>
      <div class="member">Deputy Director Max Cameron</div>
      <div class="contact">
        <a :href="email('trmn.sis.director', 'gmail.com')">{{
          email("trmn.sis.director", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">GSN</div>
      <div class="member">VACANT*</div>
      <div class="contact">&nbsp;</div>

      <div class="aor">IAN</div>
      <div class="member">VACANT*</div>
      <div class="contact">&nbsp;</div>

      <div class="aor">RMMC</div>
      <div class="member">COL Roon Marchant*</div>
      <div class="contact">
        <a :href="email('roonmarchant', 'gmail.com')">{{
          email("roonmarchant", "gmail.com").substring(7)
        }}</a>
      </div>

      <div class="aor">SFC</div>
      <div class="member">VACANT*</div>
      <div class="contact">&nbsp;</div>
    </div>
    <div class="vacant">
      <span>*</span>
      Contact the Fleet/Component Commanding Officer if the agent slot is
      vacant. If you are interested in filling one of the vacancies, contact
      LCDR Patrick Giese at
      <a :href="email('masternav', 'icloud.com')">{{
        email("masternav", "icloud.com").substring(7)
      }}</a>
      or LTSG Jay Griffin, BuPlan CoS at
      <a :href="email('cos', 'buplan.trmn.org')">{{
        email("cos", "buplan.trmn.org").substring(7)
      }}</a>
    </div>
  </div>
</template>

<script>
import loggedIn from "../mixins/loggedIn";
import tokenExpiration from "../mixins/tokenExpiration";

export default {
  mixins: [loggedIn, tokenExpiration],
  methods: {
    email(user, domain) {
      return `mailto:${user}@${domain}`;
    },
  },
};
</script>

<style scoped>
.about {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 6px;
}

.column-head {
  font-weight: 700;
  border-bottom: 1px solid black;
}

.vacant {
  margin-top: 12px;
}
</style>
